import { View } from 'react-native'
import React from 'react'
import { Text, Form, Item, Input, Label } from 'native-base'
import { getFieldUISchemaData } from '../../../utils/fieldutils/fieldType'
import PropTypes from 'prop-types'

export default function Barcode(props) {
  const {
    field: { id, uiSchema: fdUISchema },
    t,
    fieldAnswer,
  } = props
  const crfData = { ...fieldAnswer }
  return (
    <View
      style={{
        flex: 1,
        marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
        marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
      }}
      testID={`${id}-barcode-view`}
      accessible={true}
    >
      <Form>
        <Item fixedLabel>
          <Label>Barcode</Label>
          {props.form.getFieldDecorator(`${id}`, {
            initialValue: crfData ? crfData.fieldValue : null,
            rules: [
              {
                required: false,
                message: t('ReqField'),
              },
            ],
          })(
            <Input
              accessible={true}
              testID={`${id}-barcode-input`}
              value={props.form.getFieldValue(id)}
              editable={false}
            />
          )}
        </Item>
      </Form>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ color: 'gray', textAlign: 'center', margin: 5 }}>
          {t('BarcodeMessage')}
        </Text>
      </View>
    </View>
  )
}


Barcode.defaultProps = {
  t: ()=> null,
  fieldAnswer: {},
};

Barcode.propTypes = {
  field: PropTypes.object.isRequired,
  t: PropTypes.func,
  fieldAnswer: PropTypes.object,
  form: PropTypes.object.isRequired,
};