import React, { Component } from 'react'
import NumericRatingScale from '../scales/numericRatingScale'
import PropTypes from 'prop-types'
import constants from '../../constants/constants'
import _ from 'lodash'
import { connect } from 'react-redux'
import { View } from 'react-native'
import ErrorMessage from '../form/ErrorMessage'

const {SCALE_POSITION} = constants

export class NRScale extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedValue: null,
    }
  }

  onNumberChange = (value) => {
    const {
      form,
      field: { id, dictionary: { isPercentage}},
    } = this.props
    const { setFieldsValue, validateFields  } = form
    const percentageSymbol = isPercentage ? '%' : ''
    setFieldsValue({
      [id]: value+percentageSymbol,
    })
    validateFields([id], { force: true })
    this.setState((prevState) => ({
      ...prevState,
      selectedValue: value+percentageSymbol,
    }))
  }

  render() {
    const {
      field: {
        dictionary: { options, start, end, step, orientation, isPercentage, showValue, labelText, scaleVerticalAlignment },
        id,
        isRequired,
        uiSchema: fdUISchema,
      },
      form,
      t,
      disabled,
      fieldAnswer,
      onMobile,
    } = this.props
    const crfData = { ...fieldAnswer }
    return (
      <View testID={`${id}-nrs-view`}>
    {form.getFieldDecorator(id, {
      initialValue: !_.isEmpty(crfData)  ? crfData?.fieldValue : null,
      rules: isRequired && !disabled
        ? [
          {
            required: isRequired && !disabled,
            message: t('ReqField'),
          },
        ]
        : [] ,
    })(
      <NumericRatingScale
        orientation={orientation}
        start={Number(start)}
        end={Number(end)}
        options={options}
        step={Number(step)}
        onNumberChange={this.onNumberChange}
        disabled={disabled}
        fdUISchema={fdUISchema}
        id={id}
        isPercentage={isPercentage}
        showValueConfig= {{
          showValue: showValue,
          labelText: labelText
        }}
        scaleVerticalAlignment={scaleVerticalAlignment}
        onMobile={onMobile}
      />
    )}
      <ErrorMessage id={id} isRequired={isRequired} disabled={disabled} form={form} />
    </View>
    )
  }
}

NRScale.defaultProps = {
  field : {
    dictionary: {
      isPercentage: false,
      options: [],
      start: 0,
      end: 10,
      step: 1,
      orientation: null,
      showValue: false,
      labelText: null,
      scaleVerticalAlignment: SCALE_POSITION.CENTRE_OF_THE_PAGE
    },
  },
  onMobile: false,
}

NRScale.propTypes = {
  field: PropTypes.shape({
    dictionary: PropTypes.shape({
        isPercentage: PropTypes.bool,
        options: PropTypes.arrayOf(Object),
        start: PropTypes.number,
        end: PropTypes.number,
        step: PropTypes.number,
        orientation: PropTypes.string,
        showValue: PropTypes.bool,
        labelText: PropTypes.string,
        scaleVerticalAlignment: PropTypes.string
    }),
  }), 
  onMobile: PropTypes.bool,
  form: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  onMobile: state?.appStatus?.onMobile
})

export default connect(
  mapStateToProps,
  null
)(NRScale)
