import { parse, stringify } from 'flatted'
import { applyMiddleware, compose, createStore } from 'redux'
import { createTransform, persistReducer, persistStore, createMigrate } from 'redux-persist'
import thunk from 'redux-thunk'
import AppReducer from '../reducers/AppReducer'
import { reduxStorage } from '../utils/storage/persistConfig'
import migrations from './migrations'
import { loggerMiddleware } from '../middleware/middleware'

const initialState = {}

export const transformCircular = createTransform(
  (inboundState, key) => stringify(inboundState),
  (outboundState, key) => parse(outboundState)
)

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'root',
  keyPrefix: '',
  // Storage Method (React Native)
  storage: reduxStorage,
  version: 0,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'studyMetaData',
    'crfData',
    'chat',
    'appStatus',
    'study',
    'users',
    'subject',
    'syncQueue',
    'session',
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    // 'subject'
  ],
  transforms: [transformCircular],
  migrate: createMigrate(migrations, { debug: true }),
}

const persistedReducer = persistReducer(persistConfig, AppReducer)

const middleware = [thunk, loggerMiddleware]
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
      })
    : compose
const enhancer = composeEnhancers(applyMiddleware(...middleware))

const store = createStore(persistedReducer, initialState, enhancer)

const persistor = persistStore(store)

export { store, persistor }
