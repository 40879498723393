import * as Font from 'expo-font'
import * as Updates from 'expo-updates'
import React, { Component } from 'react'
import { Alert, Platform, SafeAreaView, View } from 'react-native'
import Toast, { BaseToast } from 'react-native-toast-message'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import toasters from '../constants/toasters'
import { persistor, store } from '../store/configStore'
import i18n from '../utils/localization/localizationUtils'
import { getItem, putItem } from '../utils/secureStorageUtils'
import AppNavigation from './AppNavigator'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import DeviceInfo from 'react-native-device-info'
import _ from 'lodash'
import { startBackGroundJob, stopJobWhenNoImagesToBeDownloaded } from '../utils/backgroundTask/downloadImageUtil'
import { updateOnMobileStatus } from '../actions/storeAppStatus'
import { checkImagesValidAndRetryDownload } from '../actions/studyMetaData'
import constants from '../constants/constants'
import { restoreInProgressFormToActive } from '../actions/syncQueue'

const toastConfig = {
  error: (internalState) => toasters(internalState, 'error'),
  success: (internalState) => toasters(internalState, 'success'),
  info: (internalState) => toasters(internalState, 'info'),
  inprogress: (props) => (
    <BaseToast
      {...props}
      style={{ borderLeftColor: '#f57b42' }}
      contentContainerStyle={{ paddingHorizontal: 15 }}
      text1Style={{
        fontSize: 15,
        fontWeight: '400',
      }}
    />
  ),
}

class App extends Component {
  fallbackLocale = constants.locale.english

  state = {
    loading: true,
    locale: this.fallbackLocale,
    showReloadDialog: false,
    checkingUpdate: false,
  }

  async componentDidMount() {
    console.disableYellowBox = true
    this.setState({
      loading: true,
    })
    this.setResolution()
    this.checkUpdates()
    this.loadFontsAsync()
    this.setStoredLocale()
    store.dispatch(stopJobWhenNoImagesToBeDownloaded())
    this.startBackgroundJobBasedOnPlatform()
    this.reactivateInProgressForm()

    this.setState({
      loading: false,
    })

  }

  componentDidUpdate(_prevProps, prevState) {
    const { showReloadDialog } = this.state
    if (showReloadDialog === true && showReloadDialog !== prevState.showReloadDialog) {
      Alert.alert(
        'Update',
        'A new update is available. Please restart the app to apply the update.',
        [
          {
            text: 'Reload',
            onPress: () => {
              Updates.reloadAsync()
            },
          },
        ],
        { cancelable: false }
      )
    }
  }

  reactivateInProgressForm = () => {
    store.dispatch(restoreInProgressFormToActive())
  }

  setResolution = async () => {
      const deviceOs = await this.getDeviceOs()
      const onMobile = this.checkAppOnMobile(deviceOs)
      store.dispatch(updateOnMobileStatus(onMobile))
  }
  checkAppOnMobile = (deviceOs) => {
    return (!_.isEmpty(deviceOs) && ((_.isEqual(deviceOs, 'Android') || _.isEqual(deviceOs, 'iOS') || _.isEqual(deviceOs, 'Windows')) && this.isMobileDeviceByScreenSize())) || (Platform.OS !== 'web')
  }
  

  componentWillUnmount() {
    const state = store.getState()
    clearInterval(state?.appStatus?.syncQueueTimerId)
  }


  checkUpdates = async () => {
    if (Platform.OS !== 'web') {
      if (this.state.checkingUpdate !== true) {
        console.log('Checking for an update...')
        this.setState({
          checkingUpdate: true,
        })

        try {
          const update = await Updates.checkForUpdateAsync()
          if (update.isAvailable) {
            console.log('An update was found, downloading...')
            await Updates.fetchUpdateAsync()
            this.setState({
              showReloadDialog: true,
            })
          } else {
            console.log('No updates were found')
          }
        } catch (e) {
          console.error('Error while trying to check for updates', e)
        }
        this.setState({
          checkingUpdate: false,
        })
      } else {
        console.log('Currently checking for an update')
      }
    }
  }

  loadFontsAsync = async () => {
    try {
      await Font.loadAsync({
        Roboto: require('native-base/Fonts/Roboto.ttf'), // used for print
        Inter: require('../../assets/Inter/Inter-Regular.ttf'), // used for UI
      })
    } catch (e) {
      console.error('Font loading error: ', e)
    }
  }

  setStoredLocale = async () => {
    try {
      const locale = getItem('locale') || this.fallbackLocale
      this.setState({
        locale,
      })
    } catch (e) {
      this.setState({
        locale: this.fallbackLocale,
      })
      console.error('Error while trying to get locale from storage', e)
    }
  }

  setLocale = (locale) => {
    this.setState({ locale })
    putItem(locale, 'locale')
  }

  t = (scope, options) => {
    return i18n.t(scope, { locale: this.state.locale, ...options, defaultValue: scope})}

isMobileDeviceByScreenSize() {
    // Assuming a standard 96 DPI (dots per inch) for screens, adjust if necessary
    const dpi = window.devicePixelRatio * 96
    // Get the screen's width and height in inches
    const screenWidthInches = window.screen.width / dpi

    const screenHeightInches = window.screen.height / dpi
    // Calculate the diagonal size of the screen in inches
    const diagonalInches = Math.sqrt(screenWidthInches ** 2 + screenHeightInches ** 2)
    // Consider the device as mobile if the screen size is under 7 inches
    return diagonalInches < 7
  }
  //     const res = await DeviceInfo.getBaseOs()

  getDeviceOs = async () => {
    const res = await DeviceInfo.getBaseOs()
    return res;
  }

  startBackgroundJobBasedOnPlatform = async () => {
    if(Platform.OS === 'web'){
      store.dispatch(checkImagesValidAndRetryDownload())
    }
    if(Platform.OS === 'ios'){
      store.dispatch(startBackGroundJob())
    }
  }

  render() {
    const { loading, locale } = this.state

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SafeAreaProvider>
            {Platform.OS === 'ios' ? (
              <SafeAreaView style={{ flex: 1 }}>
                {!loading ? (
                  <AppNavigation
                    screenProps={{
                      t: this.t,
                      locale: locale,
                      setLocale: this.setLocale,
                      appType: store.getState().appStatus.appType,
                    }}
                  />
                ) : (
                  <View />
                )}
              </SafeAreaView>
            ) : !loading ? (
              <AppNavigation
                screenProps={{
                  t: this.t,
                  locale: locale,
                  setLocale: this.setLocale,
                  appType: store.getState().appStatus.appType,
                }}
              />
            ) : (
              <View />
            )}
            <Toast config={toastConfig} ref={(ref) => Toast.setRef(ref)} />
          </SafeAreaProvider>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
