import React, { Component } from 'react'
import { Container, ListItem, Body } from 'native-base'
import PropTypes from 'prop-types'
import { View, FlatList, Text } from 'react-native'
import styles from '../../styles/listStyles'
import SearchBox from '../common/SearchBox'
class StudySiteList extends Component {
  constructor(props) {
    super(props)
  }

  renderList = () => {
    const {list, onSiteSelect, totalSitesCount, t} = this.props
    if(list.length > 0){
   /* ------------- Render list ------------------------ */
      return (
        <FlatList
            data={list}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <ListItem
                thumbnail
                onPress={() => {
                    onSiteSelect(item)
                }}
              >
                <Body>
                  <View style={{ justifyContent: 'flex-start' }}>
                    <Text style={styles.textStyle}>{item?.name}</Text>
                  </View>
                </Body>
              </ListItem>
            )}
          />
      )
    }else{
      /* ------------- Render No data ------------------------ */
      return (
        <View
            style={{
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={[styles.textStyle, { textAlign: 'center' }]}>
              { totalSitesCount === 0? t('NoSites'):  t('NoData')}</Text>
          </View>
      )

    }
  }
  render() {
    const { onSearchTerm, searchTerm, t } = this.props

    return (
      <Container style={styles.containerStyle}>
        {/* ------------- search bar ------------------------ */}
        <SearchBox
           searchTerm={searchTerm}
           onSearchTerm={onSearchTerm}
           t={t}
        />

        {/* ------------- Loading ------------------------ */}
        {this.renderList()}
      </Container>
    )
  }
}

StudySiteList.defaultProps = {
  list: [],
  onSiteSelect: () => null,
  onSearchTerm: ()=> null,
  searchTerm: null,
}
StudySiteList.propTypes = {
  list: PropTypes.arrayOf(Object),
  onSiteSelect: PropTypes.func,
  onSearchTerm: PropTypes.func,
  searchTerm: PropTypes.string,
  t: PropTypes.func,
  totalSitesCount: PropTypes.number
}

export default StudySiteList
