import { SESSION_TIMEOUT } from '../actions/login'
import { CLEAR_SELECTED_CHAT, STORE_SELECTED_SVF_UPDATE, UPDATE_CURRENT_USER_ID, UPDATE_SELECTED_CALL, UPDATE_SELECTED_DATE, UPDATE_SELECTED_VISIT_FROM_ID, UPDATE_SELECTED_VISIT_ID, UPDATE_SELECTEDCHAT, STORE_CURRENT_SCREEN, UPDATE_SELECTED_FORMTYPE, UPDATE_IS_ACKNOWLEDGED, QUESTIONNAIRE_UPGRADE_REQUIRED } from '../actions/session'
import { CLEAR_USER_STORAGE } from '../actions/syncQueue'
import initialState from '../store/initialState'

export default (state = initialState.session, action) => {
  switch (action.type) {
    case SESSION_TIMEOUT:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          sessionTimeout: action.timeout,
        }
      }
    case UPDATE_CURRENT_USER_ID:
      return {
        ...state,
        currentUserId: action.userId,
      }  
    case UPDATE_SELECTED_VISIT_ID:
        return {
          ...state,
          [action.userId]: {
            ...state?.[action.userId],
            selectedSubjectVisitId: action.selectedVisitId,
          }
        }
    case UPDATE_SELECTED_VISIT_FROM_ID:
        return {
          ...state,
          [action.userId]: {
            ...state?.[action.userId],
            selectedFormId: action.visitFormId,
            selectedSvfId: action.svfId,
          }
        } 
    case UPDATE_SELECTED_DATE:
          return {
            ...state,
            [action.userId]: {
              ...state?.[action.userId],
            selectedDate: action.date,
            }
          }
    case UPDATE_SELECTED_CALL:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          selectedCall: action.selectedCall,
        }
      }
    case STORE_SELECTED_SVF_UPDATE:
        return {
          ...state,
          [action.userId]: {
            ...state?.[action.userId],
            isSelectedSvfUpdated: action.isSvfUpdated,
          },
        }
    case UPDATE_SELECTEDCHAT:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          selectedChat: action.selectedChat,
        }
       }      
    case CLEAR_SELECTED_CHAT: 
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          selectedChat: {},
        }
      } 
    case STORE_CURRENT_SCREEN:
        return {
          ...state,
          [action.userId]: {
            ...state?.[action.userId],
          currentScreen: action.screen 
        } 
      }
    case UPDATE_SELECTED_FORMTYPE:
          return {
            ...state,
            [action.userId]: {
              ...state?.[action.userId],
              selectedFormType: action.selectedFormType,
            }
          }
    case CLEAR_USER_STORAGE:
          return {
              ...state,
              [action.userId]: { }
          }
    case UPDATE_IS_ACKNOWLEDGED: {
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
        isAcknowledged: action?.isAcknowledged
        }
      }      
    }
    case QUESTIONNAIRE_UPGRADE_REQUIRED:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          isQuestionnaireUpgradeRequired: action.isQuestionnaireUpgradeRequired,
        }
      }
    default:
      return state
  }
}
