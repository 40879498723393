import createActionType from '../utils/action'

export const STORE_APP_STATUS = createActionType('STORE_APP_STATUS')
export const UPDATE_SYNC_QUEUE_TIMER = createActionType('UPDATE_SYNC_QUEUE_TIMER')
export const UPDATE_APP_TYPE = createActionType('UPDATE_APP_TYPE')

export const UPDATE_SPACE_AVAILABLE = createActionType('UPDATE_SPACE_AVAILABLE')
export const UPDATE_DOWNLOADED_IMAGE_TIMER = createActionType('UPDATE_DOWNLOADED_IMAGE_TIMER')
export const UPDATE_SPACE_ACKNOWLEDGEMENT = createActionType('UPDATE_SPACE_ACKNOWLEDGEMENT')
export const UPDATE_ON_MOBILE_STATUS = createActionType('UPDATE_ON_MOBILE_STATUS')

export const LOGIN_RETRIEVE_REQUEST = createActionType('LOGIN_RETRIEVE_REQUEST')
export const LOGIN_RETRIEVE_FAILURE = createActionType('LOGIN_RETRIEVE_AILURE')
export const UPDATE_DEVICE_TOKEN = createActionType('UPDATE_DEVICE_TOKEN')
export const UPDATE_PIN_VALIDATED = createActionType('UPDATE_PIN_VALIDATED')
export const UPDATE_DATA_LOADER = createActionType('UPDATE_DATA_LOADER')


const storeAppStatusSuccess = (status) => ({
  type: STORE_APP_STATUS,
  isDeviceOnline: status,
})


export const storeAppStatus = (status) => (dispatch) => {
  dispatch(storeAppStatusSuccess(status))
}


export const updateSyncQueueTimer = (timerId) => ({
  type: UPDATE_SYNC_QUEUE_TIMER,
  timerId,
})

export const updateAppType = (appType) => ({
  type: UPDATE_APP_TYPE,
  appType,
})


export const updateSpaceAvailabilityInStore = (isSpaceAvailable) => async (dispatch) => {
  dispatch({
    type: UPDATE_SPACE_AVAILABLE,
    data: isSpaceAvailable,
  })
}

export const updateDownloadImageTimerId = (timerId) => async (dispatch) => {
  dispatch({
    type: UPDATE_DOWNLOADED_IMAGE_TIMER,
    timerId,
  })
}

export const updateSpaceAcknowledgement = (isAcknowledged) => async (dispatch) => {
  dispatch({
    type: UPDATE_SPACE_ACKNOWLEDGEMENT,
    isAcknowledged,
  })
}

export const updateOnMobileStatus = (onMobile) => async (dispatch) => {
  dispatch({
    type: UPDATE_ON_MOBILE_STATUS,
    onMobile,
  })
}

export const retrieveLoginRequest = () => async (dispatch) => {
  dispatch({
    type: LOGIN_RETRIEVE_REQUEST,
  })
}

export const retrieveLoginFailure = (onMobile) => async (dispatch) => {
  dispatch({
    type: LOGIN_RETRIEVE_FAILURE,
  })
}


export const updateDeviceToken = (token) => async (dispatch) => {
  dispatch({
    type: UPDATE_DEVICE_TOKEN,
    token
  })
}

export const updatePinValidated = (isPinValidated) => (dispatch) => {
  dispatch({
    type:UPDATE_PIN_VALIDATED,
    isPinValidated
  })
}

export const updateDataLoader = (loading) => async (dispatch) => {
  dispatch({
    type: UPDATE_DATA_LOADER,
    loading
  })
}