import axios from 'axios'
import JSOG from 'jsog'
import _ from 'lodash'
import appConstants from '../constants/appConstants'
import { getContextBasedUrl } from './apiConfigs/utils'
import { validateToken } from '../actions/users'

let baseUrl = `${appConstants.baseUrl}/api`

const axiosConfig = axios.create({
  baseURL: baseUrl,
  headers: { 'Access-Control-Allow-Origin': '*' },
  transformResponse: [].concat(axios.defaults.transformResponse, (data) => JSOG.decode(data)),
})

axiosConfig.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error)
    if (axios.isCancel(error)) {
      console.log('Request canceled', error)
      return Promise.resolve(error)
    } else {
      return Promise.reject(error)
    }
  }
)

axiosConfig.interceptors.request.use(
  (config) => getConfig(config),
  (error) => {
    Promise.reject(error)
  }
)

const getConfig = async (config) => {
  const contextBasedUrl = getContextBasedUrl(config.url);
  config.url = contextBasedUrl;
  const data = await validateToken()
  if (!_.isEmpty(data)) {
    config.headers['Authorization'] = data.accessToken
  }else {
    config.cancelToken = new axios.CancelToken((cancel) =>
      cancel('Request cancelled due to session expiry')
    )
  }
  return config
}

export default axiosConfig
