import React from "react"
import { View } from "react-native"
import PropTypes from 'prop-types'
import SiteStaffProfileHeader from '../../clinroHeader/index'
import { backgroundColor } from "../../../../containers/NavigationScreens"
import { connect } from 'react-redux'
import Header from '../../header';

const ClinroHeaderWrapper = (props) => {
    const {loading, navigation, screenProps, title, canShowBackIcon, onBack} = props
    return loading ? (
        <View style={{
                backgroundColor: backgroundColor,
                height: 65,
                borderBottomWidth: 1
              }}
            >
            <Header canShowBackIcon={false} title={null} navigation={navigation} headerRight={false} />
        </View>
    ) : (
        <SiteStaffProfileHeader
            navigation={navigation}
            title={title}
            canShowBackIcon={canShowBackIcon}
            screenProps={screenProps}   
            onBack={onBack}       
        />
    )
}


const mapStateToProps = (state) => ({
    loading: state.appStatus.loading,
})
ClinroHeaderWrapper.propTypes = {
    loading: PropTypes.bool, 
    navigation: PropTypes.instanceOf(Object), 
    screenProps: PropTypes.instanceOf(Object), 
    title: PropTypes.string, 
    canShowBackIcon: PropTypes.bool,
    onBack: PropTypes.func
}

export default connect(mapStateToProps, null)(ClinroHeaderWrapper)
