import React from "react"
import { Text } from "react-native"
import { styles } from "../fields/styles"
import PropTypes from "prop-types"

const ErrorMessage = (props) => {
    const {id, isRequired, disabled, form} = props
    const {getFieldError} = form
    
    return( 
        <Text style={styles.errorText}>
            {isRequired && !(disabled) && getFieldError(id)}
        </Text>
    )
}


ErrorMessage.defaultProps = {
    form: {},
    id: null,
    isRequired: false,
    disabled: false,
};
  
ErrorMessage.propTypes = {
    form: PropTypes.object,
    id: PropTypes.string,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default ErrorMessage
