import React, { Component } from 'react'
import StudyList from '../components/study/list'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { retrieveStudies, updateSelectedStudy } from '../actions/study'
import { NavigationEvents } from 'react-navigation'
import { clearSelectedSubject } from '../actions/subject'
import { updateSelectedStudySite } from '../actions/studySite'
import Loader from '../components/common/Loader'
import PropTypes from 'prop-types'
import _ from 'lodash'
class StudyListPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      searchTerm: '',
    }
  }


  getStudies = async () => {
    const {retrieveStudies: retrieveStudiesOfSite, navigation} = this.props
    const autoSelection = navigation.getParam('autoSelection', true);
    try {
      const studies = await retrieveStudiesOfSite()
      if(studies?.length === 1 && autoSelection){
        this.onStudySelect(studies[0])
      }
      this.setState({list: studies})
    } catch (error) {
      console.log(error)
    }
  }

  componentDidMount(){
    this.getStudies()
  }

  clearStudy = async () => {
    const { updateSelectedStudy: updateSelectedStudyInStore , updateSelectedStudySite, clearSelectedSubject} = this.props
    this.setState({searchTerm: '', list: []})
    clearSelectedSubject(null)
    updateSelectedStudySite(null)
    updateSelectedStudyInStore(null)
    
  }

  onStudySelect = async (study) => {
    const { updateSelectedStudy: updateSelectedStudyInStore, navigation } = this.props
    const autoSelection = navigation.getParam('autoSelection', true);
    await updateSelectedStudyInStore(study)
    if(navigation?.navigate){
      navigation.navigate('SiteListPage', {autoSelection})
    }
  }

  onSearchTerm = (searchTerm) => {
    this.setState({searchTerm: searchTerm})
  }

  getList = () => {
    const {searchTerm, list} = this.state
    return !_.isEmpty(searchTerm)? _.filter(list, study => _.includes(_.lowerCase(study?.studyNumber), _.lowerCase(searchTerm))) : list
  }


  render() {
    const { searchTerm} = this.state
    const {screenProps: {t}, loading} = this.props
    if(loading){
      return ( <Loader loading={loading} text={t('Loading')}/>)
    }else{
      return (
        <View style={{ flex: 1 }}>
          <NavigationEvents
            onDidFocus={() => {
              this.clearStudy()
            }}
          />
          <StudyList list={this.getList()} onStudySelect={this.onStudySelect} onSearchTerm={this.onSearchTerm} searchTerm={searchTerm} t={t}/>
        </View>
      )
    }

   
    
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.appStatus.loading,
  }
}


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveStudies,
      updateSelectedStudy,
      updateSelectedStudySite, 
      clearSelectedSubject
    },
    dispatch
  )

StudyListPage.propTypes = {
  retrieveStudies: PropTypes.func,
  updateSelectedStudy: PropTypes.func, 
  updateSelectedStudySite: PropTypes.func,
  clearSelectedSubject: PropTypes.func,
  navigation: PropTypes.shape({
      navigate: PropTypes.func,
      getParam: PropTypes.func,
  }),
  screenProps:PropTypes.shape({
    t: PropTypes.func,
  }),
  loading: PropTypes.bool
}
export default connect(mapStateToProps, mapDispatchToProps)(StudyListPage)
