import enLocale from '../utils/localization/locale_en.json'

import genericapi from '../utils/genericapi'
import { getUserId } from '../selectors/user'
import showToast from '../utils/toast'
import { retrieveStudyDetailsSuccess } from './studyMetaData'
import constants from '../constants/constants'
import { updateDataLoader } from './storeAppStatus'

const { ContextProperties: {PRIMARY_ORG_CODE} } = constants;

export const retrieveStudies = () => async (dispatch, getState) => {
  dispatch(updateDataLoader(true))
  const userId = getUserId(getState())
  try {
    const res = await genericapi.get(`${PRIMARY_ORG_CODE}/users/${userId}/studies`)
    dispatch(updateDataLoader(false))
    return res.data
  } catch (error) {
    console.log(error)
    dispatch(updateDataLoader(false))
    showToast(enLocale.FailedRetrieve, 'danger', 3000)
    throw error
  }
}

export const updateSelectedStudy = (study) => async (dispatch, getState) => {
  const userId = getUserId(getState())
  dispatch(retrieveStudyDetailsSuccess(study, userId))
}


