import React from "react"
import { Item, Input,Icon } from 'native-base'
import { View } from "react-native"
import styles from '../../styles/listStyles'
import PropTypes from 'prop-types'

const SearchBox = (props) => {
    const {searchTerm, onSearchTerm, t} = props
    return (
        <View style={{ margin: 10 }}>
                  <Item searchBar rounded>
                    <Icon name="ios-search" style={{ padding: 2 }} />
                    <Input
                      placeholder={t('Search')}
                      style={styles.textStyle}
                      autoCorrect={false}
                      value={searchTerm}
                      onChangeText={(term) => {
                        onSearchTerm(term)
                      }}
                    />
                  </Item>
        </View>
    )
}


SearchBox.propTypes = {
  searchTerm: PropTypes.string, 
  onSearchTerm: PropTypes.func,
  t: PropTypes.func
}
export default SearchBox

