import React from 'react'
import { View, Text } from 'react-native'
import PropTypes from 'prop-types'
function LoginHeader(props) {
  const { t, type } = props
  return (
    <View>
      <Text
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: 20,
          fontFamily: 'Inter',
          color: type ? '#ffa500' : '#469ae5',
        }}
      >
        {type ? t('ClinicianLogin') : t('ParticipantLogin')}
      </Text>
    </View>
  )
}

LoginHeader.prototype = {
  t: PropTypes.func,
  type: PropTypes.string,
}
export default LoginHeader
