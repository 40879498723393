import _ from 'lodash'
import React from 'react'
import { View, StyleSheet } from 'react-native'
import ClickableImageScreen from '../components/ClickableImageScreen'
import { getFieldUISchemaData } from '../utils/fieldutils/fieldType'
import ImageOptions from '../components/clickableImage/options'
import { downloadAndReturnImageUrl, readImage } from '../utils/backgroundTask/downloadImageUtil'
import { connect } from 'react-redux'
import constants from '../constants/constants'
import { getDeviceStatus } from '../actions/users'
import PropTypes from 'prop-types'
import { getFormPreviewClickableImages, getUserClickableImages } from '../selectors/studyMetaData'
import ErrorMessage from '../components/form/ErrorMessage'

const {DO_NOT_DISPLAY_CHOICES} = constants
class FormClickableImagePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldDictionary: {},
      selectedOptions: [],
      imageUri: null,
      parentViewDimensions: {}
    }
    this.viewRef = React.createRef()
  }

  getCurrentContextClickableImages = (mode, prevProps=null) => {
    const {previewClickableImages, userClickableImages} = this.props
    if(mode && mode === 'preview'){
      return prevProps ? prevProps.previewClickableImages : previewClickableImages
    }
      return prevProps ? prevProps.userClickableImages : userClickableImages
  }

  componentDidMount() {
    const {
      field: { dictionary },
      fieldAnswer,
    } = this.props
    if (dictionary) {
      const areas = _.map(dictionary.map.areas, (a, index) => ({
        ...a,
        coords: dictionary.map.areas[index].coords,
        shape: dictionary.map.areas[index].shape,
        // fillColor: _.isEmpty(imageDictionary.map.areas[index].fillColor) ? '#e0e0e0' : imageDictionary.map.areas[index].fillColor,
        fillColor: '#9e9e9e',
      }))
      dictionary.map.areas = areas
      const selectedOptions = fieldAnswer?.optionOid ? JSON.parse(fieldAnswer?.optionOid) : []
      this.setState(
        (prevState) => ({
          ...prevState,
          fieldDictionary: {
            ...dictionary,
            map: {
              ...dictionary.map,
              areas,
            },
          },
          selectedOptions,
        }),
      )
      this.getSourceUri(dictionary?.imageCode, dictionary?.img?.src)
    }
  }

  componentDidUpdate(prevProps){
    const { field: { dictionary },mode} = this.props
    const currentFormClickableImages = this.getCurrentContextClickableImages(mode); 
    const previousClickableImages = this.getCurrentContextClickableImages(mode, prevProps); 
    if(mode !== 'printView' && currentFormClickableImages != previousClickableImages &&  !_.isEmpty(currentFormClickableImages?.[dictionary.imageCode]) && !_.isEqual(previousClickableImages?.[dictionary.imageCode]?.downloadedUrl, currentFormClickableImages?.[dictionary.imageCode]?.downloadedUrl)){
      this.getSourceUri(dictionary?.imageCode, dictionary?.img?.src)
      this.handleLayout()
    }
  }
  getSourceUri = async (imageCode, src) => {
    const { mode, isStorageAvailable} = this.props
    if(mode === 'printView'){
      this.setState({imageUri: src})
    }else if(!isStorageAvailable && getDeviceStatus()){
        //showing images directly from api response when device storage is not available
          this.getImageUri(src);
    }else {
      const images = this.getCurrentContextClickableImages(mode)
      const image = images?.[imageCode];
      if(image?.downloadedUrl){
          const url = await readImage(image?.downloadedUrl);
          this.setState({imageUri: url})
      }
    } 
  }
  

  getImageUri = async(src) => {
    const url = await downloadAndReturnImageUrl(src, true);
    this.setState({imageUri: url})
  }

  handleLayout = () => {
    this.viewRef?.current?.measure((x, y, width, height, pageX, pageY) => {
      this.setState({ parentViewDimensions: { width, height, x, y, pageX, pageY } })
    });
  }

  render() {
    const {
      disabled,
      form: { getFieldDecorator },
      field: {
        dictionary: {
          map: { areas },
          showOptions,
        },
        id,
        isRequired,
        uiSchema: fdUISchema,
        fieldGroup,
      },
      form,
      t,
      fieldAnswer,
      footerPageY,
      onMobile,
    } = this.props
    const hasMultipleQuestionInPage = fieldGroup?.uiSchema?.mobilePageSize === 1
    const { fieldDictionary, imageUri, parentViewDimensions } = this.state
    if (!fieldDictionary && fieldDictionary.map) {
      return <View></View>
    }
    const { selectedOptions } = this.state
    const crfData = { ...fieldAnswer }
    return (
      imageUri && <View
        style={{
          flex: 1,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'space-between'
          marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
          marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
          paddingBottom: 10
        }}
      >
        <View onLayout={this.handleLayout} ref={this.viewRef} style={ onMobile && !hasMultipleQuestionInPage ? styles.container : {}} >
          {getFieldDecorator(id, {
            initialValue: crfData && crfData.optionOid ? JSON.parse(crfData.optionOid) : null,
            // valuePropName: 'selectedValue',
            rules: isRequired && !disabled
        ? [
          {
            required: isRequired && !disabled,
            message: t('ReqField'),
          },
        ]
        : [] ,
          })(
            <ClickableImageScreen
              obj={fieldDictionary}
              setValue={(val) => {
                if(!disabled){
                  this.props.form.setFieldsValue({
                    [id]: !_.isEmpty(val) ? JSON.stringify(val) : null,
                  })
                  this.props.form.validateFields([id], { force: true })
                this.setState({ selectedOptions: val })
                }
              }}
              testID={`ClickableImage-Field-${id}`}
              disabled={disabled}
              imageUri={imageUri}
              t = {t}
              parentViewDimensions={parentViewDimensions}
              footerPageY={footerPageY}
              hasMultipleQuestionInPage = {hasMultipleQuestionInPage}
              onMobile={onMobile}
            />
          )}
        </View>
        {showOptions !== DO_NOT_DISPLAY_CHOICES && (
          <ImageOptions selfScored={fieldGroup.selfScored} disabled={disabled} areas={areas} selectedOptions={selectedOptions} />
          )}
          
        <ErrorMessage id={id} isRequired={isRequired} disabled={disabled} form={form} />
      </View>
    )
  }
}
const styles = StyleSheet.create({
  container: {
    height: 500,
  },
});

FormClickableImagePage.defaultProps = {
  userClickableImages: {},
  previewClickableImages: {},
  isStorageAvailable: true,
  disabled: false,
  fieldAnswer: {},
  field: {},
  mode: null,
  onMobile: false,
}

FormClickableImagePage.propTypes = {
  isStorageAvailable: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldAnswer: PropTypes.instanceOf(Object),
  field: PropTypes.instanceOf(Object),
  mode: PropTypes.string,
  onMobile: PropTypes.bool,
  userClickableImages: PropTypes.instanceOf(Object),
  previewClickableImages: PropTypes.instanceOf(Object),
}

const mapStateToProps = (state) => ({
  userClickableImages: getUserClickableImages(state),
  isStorageAvailable: state.appStatus.storage.isStorageAvailable,
  onMobile: state.appStatus?.onMobile,
  previewClickableImages: getFormPreviewClickableImages(state)
})

  FormClickableImagePage.defaultProps = {
    footerPageY: 0,
    disabled: false,
    form: {},
    field: {},
    t: null,
    fieldAnswer: {},
  }
  FormClickableImagePage.propTypes = {
    footerPageY: PropTypes.number,
    disabled: PropTypes.bool,
    form: PropTypes.instanceOf(Object),
    field: PropTypes.instanceOf(Object),
    t: PropTypes.string,
    fieldAnswer: PropTypes.instanceOf(Object),
  }

export default connect(
  mapStateToProps,
  null
)(FormClickableImagePage)
