import React, { Component } from 'react'
import { Container, ListItem, Body } from 'native-base'
import PropTypes from 'prop-types'
import { View, FlatList, Text } from 'react-native'
import styles from '../../styles/listStyles'
import appConstants from '../../constants/constants'
import moment from 'moment'
import SearchBox from '../common/SearchBox'

class SubjectList extends Component {
  constructor(props) {
    super(props)
  }
  
  renderList = () => {
    const { list, onSubjectSelect, t} = this.props
    if(list.length> 0){
      //----------------------List------------------
      return (
        <FlatList
        data={list}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <ListItem
            thumbnail
            onPress={() => {
              onSubjectSelect(item)
            }}
          >
            <Body>
              <View style={{ justifyContent: 'flex-start' }}>
                <Text style={styles.textStyle}>{item.subjectNo}</Text>
                <Text style={styles.subTextStyle}>
                  Status: {appConstants.subjectStatus[item.status]}
                </Text>
                <Text style={styles.subTextStyle}>
                  Diary Start Date:{' '}
                  {item.diaryStartDate ? moment(item.diaryStartDate).format('DD-MMM-YYYY') : ''}
                </Text>
              </View>
            </Body>
          </ListItem>
        )}
      />
      )
    }else{
      // -----------------No Data----------------
      return ( 
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={[styles.textStyle, { textAlign: 'center' }]}>{t('NoData')}</Text>
      </View>)
    }
  }

  render() {
    const { onSearchTerm, searchTerm, t} = this.props

    return (
      <Container style={styles.containerStyle}>
        {/* ------------- search bar ------------------------ */}
        <SearchBox
           searchTerm={searchTerm}
           onSearchTerm={onSearchTerm}
           t={t}
        />

        {/* ------------- Loading ------------------------ */}
        {this.renderList()}
      </Container>
    )
  }
}

SubjectList.defaultProps = {
  onSubjectSelect: () => null,
  list: [],
  searchTerm: null,
  onSearchTerm: () => null,
}
SubjectList.propTypes = {
  onSubjectSelect: PropTypes.func,
  list : PropTypes.array,
  searchTerm: PropTypes.string,
  onSearchTerm: PropTypes.func,
  t: PropTypes.func
}

export default SubjectList
