import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { styles } from '../styles'
import CustomTextInput from '../../common/CustomTextInput'

export class TextArea extends Component {
  state = {}


  render() {
    const {
      t,
      disabled,
      changeFieldValue,
      value
    } = this.props
    return (
      <CustomTextInput
        onChangeText={changeFieldValue}
        placeholder={t('EnterHere')}
        editable={!disabled}
        multiline={true}
        value={value}
        style={[{ height: 100 }, styles.textArea, styles.textBoxBorderColor]}
      />
    )
  }
}

TextArea.defaultProps = {
  t: (key) => key,
  disabled: false,
  changeFieldValue: () => {},
  value: null,
}

TextArea.propTypes = {
  t: PropTypes.func,
  disabled: PropTypes.bool,
  changeFieldValue: PropTypes.func,
  value: null,
}