import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { backgroundColor } from '../../../../containers/NavigationScreens';
import _ from 'lodash';
import { FontAwesome } from "@expo/vector-icons";
import PropTypes from 'prop-types';
import { Colors } from '../../ColorScheme';

const StudySiteInfo = (props) => {
  const { study, subject, site, versionInfo, navigation } = props

  const renderEditIcon = (screen) => {
    return ( 
      <TouchableOpacity
        onPress={() => {
          navigation.navigate(screen, {autoSelection: false})
        }}
      >
        <FontAwesome
          name="pencil"
          style={{ fontSize: 14, color: Colors.mediumPurple, marginLeft: 8 }}
        />
    </TouchableOpacity>
    )
  }
  
  return (
    <View
      style={{
        backgroundColor: backgroundColor,
        marginBottom: 10,
      }}
    >
      <View style={{ flexDirection: 'row', marginLeft: 5 }}>
        <Text
          style={{
            fontSize: 16,
            color: 'rgba(58,53,65,.68)',
            fontFamily: 'Inter',
            fontWeight: '500',
          }}
        >
          Study: {' ' + study.studyNumber}
        </Text>
        {renderEditIcon('StudyListPage')}
      </View>
        <View>
        {!_.isEmpty(site) && (
          <View style={{ flexDirection: 'row', marginTop: 4, marginLeft: 5 }}>
            <Text
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                color: 'rgba(58,53,65,.68)',
                fontWeight: '500',
              }}
            >
              Site: {' ' + site.name}
            </Text>
            {renderEditIcon('SiteListPage')}
          </View>
        )}
          {!_.isEmpty(subject) && (
          <View>
            <View style={{ flexDirection: 'row', marginTop: 4, marginLeft: 5 }}>
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  color: 'rgba(58,53,65,.68)',
                  fontWeight: '500',
                }}
              >
                Subject: {' ' + subject.subjectNo}
              </Text>
              {renderEditIcon('SubjectListPage')}
            </View>
            <View style={{ flexDirection: 'row', marginTop: 4, marginLeft: 5 }}>
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  color: 'rgba(58,53,65,.68)',
                  fontWeight: '500',
                }}
              >
                Version: {!_.isEmpty(versionInfo) ? ' ' + versionInfo.versionNumber : ''}
              </Text>
            </View>
          </View>
          )}
        </View>
    </View>
  )
}


StudySiteInfo.propTypes = {
    study: PropTypes.shape({
      studyNumber: PropTypes.string,
    }),
    subject: PropTypes.shape({
      subjectNo: PropTypes.string,
    }),
    site:  PropTypes.shape({
      name: PropTypes.string,
    }),
    versionInfo: PropTypes.shape({
      versionNumber: PropTypes.string,
    }),
    navigation: PropTypes.shape({
        navigate: PropTypes.func,
    }),
}
export default StudySiteInfo
