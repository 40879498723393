import React, { useEffect, useState, useRef } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

const ErrorsOverview = (props) => {
  const { errorKeys, t, validateErrors, onMobile } = props
  const [currentIndex, setCurrentIndex] = useState(0)
  const currentIndexRef = useRef(currentIndex)

  useEffect(() => {
    currentIndexRef.current = currentIndex;
  }, [currentIndex])

  const onPrevious = () => {
    validateErrors(currentIndexRef.current - 1, true, false);
    if(currentIndexRef.current > 0){
      setCurrentIndex(currentIndexRef.current - 1)
    }
  }
  const onNext = () => {
    validateErrors(currentIndexRef.current + 1, true, false);
    if(currentIndexRef.current < errorKeys.length - 1){
      setCurrentIndex(currentIndexRef.current + 1)
    }
  }
  useEffect(() => {
    if(!onMobile){
      const handleKeyDown = (event) => {
        if (event.key === 'Tab') {
          if (event.shiftKey) {
            event.preventDefault()
            onPrevious()
          } else {
            event.preventDefault()
            onNext()
          }
        }
      }
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      }
    }
  }, [])

  return (
    <View style={styles.container}>
      <Text style={styles.messageText}>{t('ErrorMessage1')} {errorKeys.length} {t('ErrorMessage2')}</Text>
      <View style={styles.navigationContainer}>
        <TouchableOpacity
          style={styles.navButton}
          onPress={onPrevious}
        > 
        <MaterialCommunityIcons name="arrow-left" size={22} color={'#ff3333'} />
        </TouchableOpacity>
          <Text style={styles.indexText}>
            {currentIndex < errorKeys.length
            ? `${currentIndex + 1} / ${errorKeys.length}`
            : `${errorKeys.length} / ${errorKeys.length}`}
          </Text>
        <TouchableOpacity
          style={styles.navButton}
          onPress={onNext}
        >
          <MaterialCommunityIcons name="arrow-right" size={22} color={'#ff3333'} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#ffcccc',
    borderRadius: 8,
    padding: 10,
    margin: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between', 
    alignItems: 'center',
    borderColor: '#ff3333', 
    borderWidth: 2, 
  },
  messageText: {
    fontSize: 16,
    color: 'black',
  },
  navigationContainer: {
    flexDirection: 'row',
    alignItems: 'center', 
  },
  navButton: {
    padding: 5,
  },
  indexText: {
    fontSize: 16,
    color: '#333',
    marginHorizontal: 10,
  },
});

ErrorsOverview.defaultProps = {
  errorKeys: [],
  validateErrors: () => {},
  t: () => {},
  onMobile: false,
};

ErrorsOverview.propTypes = {
  errorKeys: PropTypes.array,
  validateErrors: PropTypes.func, 
  t: PropTypes.func,
  onMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  onMobile: state?.appStatus?.onMobile,
})
export default connect(mapStateToProps)(ErrorsOverview)
