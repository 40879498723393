import React from "react"
import { buttonBackgroundColor } from "../../containers/NavigationScreens"
import {View, ActivityIndicator, StyleSheet, Text } from 'react-native'
import PropTypes from 'prop-types'

const LoaderStyles = StyleSheet.create({
    loaderContainer: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        zIndex: 2,
    },
    textStyle: {
      textAlign: 'center',
      fontSize: 16,
      fontFamily: 'Inter',
      justifyContent: 'center',
    }
})

const Loader = (props) => {
    const {loading, text = null } = props
    return(
        loading ? (
            <View style={text? [LoaderStyles.loaderContainer, {backgroundColor: '#ffffff'}] : LoaderStyles.loaderContainer}>
              {text && (<Text style={LoaderStyles.textStyle}>{text}</Text>)}
              <ActivityIndicator
                size="large"
                color={buttonBackgroundColor}
                animating={true}
                key={loading ? 'loading' : 'not-loading'}
              />
            </View>
          ) : <></>
    )

}


Loader.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.bool,
}
export default Loader