import React, { Component } from 'react'
import CustomTextInput from '../../common/CustomTextInput'
import PropTypes from 'prop-types'

export class TextInput extends Component {
  state = {}

  render() {
    const {
      t,
      disabled,
      changeFieldValue,
      value
    } = this.props
    return (
          <CustomTextInput
            placeholder={t('EnterHere')}
            onChangeText={changeFieldValue}
            editable={!disabled}
            value={value}
            style={{
              paddingVertical: 2,
              paddingHorizontal: 5,
              borderBottomWidth: 2,
              borderBottomColor: '#cccccc',
            }}
          />
    )
  }
}

TextInput.defaultProps = {
  t: (key) => key,
  disabled: false,
  changeFieldValue: () => {},
  value: null,
}

TextInput.propTypes = {
  t: PropTypes.func,
  disabled: PropTypes.bool,
  changeFieldValue: PropTypes.func,
  value: null,
}
