import React, { useEffect, useState } from 'react'
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
  KeyboardAvoidingView,
} from 'react-native'
import { styles } from '../../styles/loginStyles'
import customStyles from '../../../styles'
import { Button } from 'native-base'
import { login, resetCurrentUser } from '../../../actions/users'
import { useDispatch} from 'react-redux'
import { buttonBackgroundColor } from '../../../containers/NavigationScreens'
import AutoHeightImage from '../../AutoHeightImage'
import Constants from 'expo-constants'
import { NavigationEvents } from 'react-navigation'
import tryalLogoBox from '../../../../assets/base64/tryalLogoBox'
import { Ionicons } from '@expo/vector-icons'
import LoginHeader from './LoginHeader'
import constants from '../../../constants/constants'
import appConstants from '../../../constants/appConstants'
import CustomTextInput from '../../common/CustomTextInput'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { _getParamFromURL } from '../../../utils/util'

function SiteLogin(props) {
  const { screenProps, navigation } = props
  const { t } = screenProps
  const dispatch = useDispatch()

  useEffect(() => {
    const primaryOrgCode = _getParamFromURL('passcode')
    if(!_.isEmpty(primaryOrgCode)){
      setPasscode(primaryOrgCode)
    }
  }, [])

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passcode, setPasscode] = useState('')
  const [usernameErrorMessage, setUsernameErrorMessage] = useState(false)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(false)
  const [passcodeErrorMessage, setPasscodeErrorMessage] = useState(false)

  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    if(_.isEmpty(passcode)) {
      setPasscodeErrorMessage(true)
    }
    if (!username) {
      setUsernameErrorMessage(true)
    }
    if (!password) {
      setPasswordErrorMessage(true)
    } 

    if(!_.isEmpty(passcode) && !_.isEmpty(username) && !_.isEmpty(password)) {
      setUsernameErrorMessage(false)
      setPasswordErrorMessage(false)
      setPasscodeErrorMessage(false)
      setLoading(true)
      try {
        await dispatch(login(username, password, t, passcode))
        navigation.navigate(`${appConstants.urlPrefix}ClinicianStudyContext`)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
        if (error?.response?.data && Number(error?.response?.data) && error?.response?.message) {
          navigation.navigate('AccountSetUp', { message: error.response.message })
        }
      }
    }
  }

  const changeToParticipant = () => {
    const { navigation } = props
    navigation.navigate('Login', { appType: constants.AppType.SUBJECT })
  }

  return (
      <KeyboardAvoidingView
        style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}
        testID={'login-page-view'}
        accessible={true}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <NavigationEvents
          onDidFocus={() => {
            dispatch(resetCurrentUser())
          }}
        />
        {loading && (
          <View
            style={{ flex: 1, position: 'absolute', alignItems: 'center', alignSelf: 'center' }}
          >
            <ActivityIndicator
              size="large"
              color={buttonBackgroundColor}
              overlayColor="rgba(0, 0, 0, 0.07)"
            />
          </View>
        )}
        <View
          style={{
            alignItems: 'center',
            paddingVertical: 30,
          }}
        >
          {Platform.OS !== 'web' ? (
            <AutoHeightImage
              source={{
                uri: `data:image/png;base64,${tryalLogoBox}`,
              }}
              width={300}
            />
          ) : (
            <Image
              source={{
                uri: `data:image/png;base64,${tryalLogoBox}`,
              }}
              resizeMode="contain"
              style={[styles.image]}
            />
          )}
        </View>
        {!Constants.expoConfig.extra.nativeApp && (
          <View style={{ backgroundColor: 'white' }}>
            <LoginHeader t={t} orgName={passcode} type={'Clinician'}></LoginHeader>
          </View>
        )}
        <View
          style={{
            flex: 1,
            width: '100%',
            marginTop: 20,
          }}
        >
          <View style={{ width: '90%', alignSelf: 'center', maxWidth: 500, marginBottom: 50, marginTop: 10 }}>
            <Text style={{ fontSize: 16, fontFamily: 'Inter', paddingBottom: 5, paddingLeft: 10 }}>{t('Organization')}:</Text>
            <CustomTextInput
              keyboardType="default"
              placeholder={t('EnterPasscode')}
              placeholderTextColor="#bdbdbd"
              style={styles.textInputStyle}
              onChangeText={(val) => setPasscode(val)}   
              value={passcode}       
            />
            {passcodeErrorMessage ? (
              <Text style={{ color: 'red' }}>{t('InvPasscode')}</Text>
            ) : (
               <Text></Text>
            )}          
          </View>
          <View style={{ width: '90%', alignSelf: 'center', maxWidth: 500, marginTop: 10 }}>
            <CustomTextInput
              placeholder={t('EnterUsername')}
              placeholderTextColor="#bdbdbd"
              style={styles.textInputStyle}
              onChangeText={(val) => setUsername(val)}
              testID="username-input"
            />
            {usernameErrorMessage ? (
              <Text style={{ color: 'red' }}>{t('EnterValidUname')}</Text>
            ) : (
              <Text></Text>
            )}
          </View>
          <View style={{ width: '90%', alignSelf: 'center', maxWidth: 500, marginTop: 10 }}>
            <CustomTextInput
              placeholder={t('EnterPassword')}
              placeholderTextColor="#bdbdbd"
              style={styles.textInputStyle}
              onChangeText={(val) => setPassword(val)}
              secureTextEntry={true}
              testID="password-input"
            />
            {passwordErrorMessage ? (
              <Text style={{ color: 'red' }}>{t('EnterValidPassword')}</Text>
            ) : (
              <Text></Text>
            )}
          </View>
          <View style={{ padding: 10, flexDirection: 'row', justifyContent: 'space-between' }}>
            <View></View>
            <Button
              style={{
                justifyContent: 'flex-end',
                padding: 10,
                borderRadius: 5,
                backgroundColor: buttonBackgroundColor,
                marginRight: 15,
              }}
              onPress={handleClick}
              loading={loading}
              testID="login-button"
            >
              <Text
                style={[{ color: 'white', textTransform: 'uppercase' }, customStyles.fontStyle]}
              >
                {t('LoginACSLogin')}
              </Text>
            </Button>
          </View>
          {!Constants.expoConfig.extra.nativeApp && (
            <TouchableOpacity
              style={{ alignSelf: 'center', marginTop: 25 }}
              onPress={() => {
                changeToParticipant()
              }}
            >
              <Text
                style={[
                  styles.buttonText,
                  {
                    color: buttonBackgroundColor,
                    fontSize: 14,
                    padding: 10,
                    borderColor: buttonBackgroundColor,
                    fontFamily: 'Inter',
                    borderWidth: 1,
                  },
                ]}
              >
                <Ionicons
                  name="ios-person"
                  size={18}
                  style={{ padding: 10 }}
                  color={buttonBackgroundColor}
                />
                {t('SwitchToParticipant')}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </KeyboardAvoidingView>
  )
}


  SiteLogin.defaultProps = {
    screenProps: {
      t: () => null,
    },
    navigation: {},
}

SiteLogin.propTypes = {
  screenProps: PropTypes.shape({
    t: PropTypes.func,
  }),
  navigation: PropTypes.instanceOf(Object),
}

export default SiteLogin
