import _ from 'lodash'
import moment from 'moment-timezone'
import { wrapFieldType } from './fieldType'
import placeFieldValues, { placeFieldValue } from './fieldDecorator'
import { wrapHidableView } from './hideableView'
import { fetchNextFieldByFieldRule } from './fieldRule'
import { DATE_TIME_FORMAT } from '../dateUtils'

export const renderFields = (fieldList, currentOrdinal, fields) => {
  const fieldComponents = []
  for (let i = 0; i < fieldList.length; i++) {
    fieldComponents.push(wrapHidableView(fields[i], fieldList[i], currentOrdinal))
  }
  return fieldComponents
}

export const buildFields = (fieldList, form, t, subjectTimezone) => {
  const fields = []
  fieldList.forEach((field) => fields.push(wrapFieldType(field, form, t, subjectTimezone)))
  return fields
}

export const createFieldsToSubmit = (
  subjectVisitId,
  fieldValues,
  fieldsList,
  selectedSvf,
  navigatedOrdinals,
  subjectTimezone,
  isFilled,
  formAnswers
) => {
  const crfData = []
  fieldsList.forEach((field) => {
    if (_.includes(navigatedOrdinals, field.ordinal) || fieldsList.length === 1) {
      crfData.push(
        placeFieldValues(
          fieldValues[field.id],
          formAnswers[selectedSvf.svfId][field.id]?.optionOid,
          field,
          selectedSvf.svfId,
          selectedSvf.crfVersionId
        )
      )
    } else if (field.fieldType !== 'result') {
      crfData.push({
        ...field.crfData,
        field: {
          id: field.id,
          fieldOid: field.fieldOid,
          fieldGroup: {
            id: field.fieldGroup.id,
          },
        },
        fieldOid: field.fieldOid,
        subjectVisitForm: {
          id: selectedSvf.svfId,
          training: selectedSvf.training,
          scheduleStartTime: selectedSvf.utcStartTime,
          scheduleEndTime: selectedSvf.utcEndTime,
        },
        crfVersion: {
          id: selectedSvf.crfVersionId,
        },
      })
    }
  })
  return getSvf(selectedSvf, subjectVisitId, subjectTimezone, crfData, isFilled)
}

export const getSvf = (selectedSvf, subjectVisitId, subjectTimezone, crfData, isFilled) => {
  const time = selectedSvf.scheduleTime
    ? moment(selectedSvf.scheduleTime, 'hh:mm A').format('HH:mm:ss')
    : null
    return {
      id: selectedSvf.svfId,
      eventOccurence: null,
      svfTimeslot: null,
      form: {
        id: selectedSvf?.form?.id,
      },
      subjectVisit: {
        id: subjectVisitId,
      },
      originatingTimezone: subjectTimezone || moment.tz.guess(true),
      completedDateTime: moment.utc().format(),
      crfVersion: {
        id: selectedSvf.crfVersionId,
      },
      crfData,
      training: selectedSvf.training,
      scheduleStartTime: selectedSvf.utcStartTime,
      scheduleEndTime: selectedSvf.utcEndTime,
      filled: isFilled,
      status: isFilled ? 'COMPLETED' : 'PARTIALLY_COMPLETED',
      subjectVisitFormTimeSlot: [{
        ...selectedSvf.svfTimeslot,
        subjectVisitForm: {
          id: selectedSvf.svfId,
        },
      }],
      setNumber: selectedSvf?.setNumber,
      visitFormOid: selectedSvf?.visitFormOid,
      scheduleTimezone: selectedSvf?.scheduleTimezone,
      originatingEventId: selectedSvf?.originatingEventId,
      allowSubmission: selectedSvf?.allowSubmission,
      locale: selectedSvf?.locale,
      scheduleId: selectedSvf?.scheduleId,
    }
}

export const changeToDefaultNextFieldOrdinal = (selectedField, fieldList) => {
  const index = _.findIndex(fieldList, (field) => field.id === selectedField.id)
  if (_.size(fieldList) > index + 1) {
    return fieldList[index + 1].ordinal
  }
  return null
}

const changeToDefaultPreviousFieldOrdinal = (selectedField, fieldList) =>
  fieldList[_.findIndex(fieldList, (field) => field.id === selectedField.id) + 1].ordinal

export const changeToNextFieldOrdinal = (selectedField, fieldList, fieldValue) => {
  try {
    const currentField = placeFieldValue(selectedField, fieldValue)
    return (
      fetchNextFieldByFieldRule(currentField, fieldList) ||
      changeToDefaultNextFieldOrdinal(selectedField, fieldList)
    )
  } catch (error) {
    console.log(error)
    return changeToDefaultNextFieldOrdinal(selectedField, fieldList)
  }
}

export const changeToPreviousFieldOrdinal = (selectedField, fieldList) => {
  try {
    return changeToDefaultPreviousFieldOrdinal(selectedField, fieldList)
  } catch (error) {
    return changeToDefaultPreviousFieldOrdinal(selectedField, fieldList)
  }
}

export const changeSelectedFieldToNextField = (currentOrdinal, fieldList) => {
  const selectedField = _.find(fieldList, (field) => field.ordinal === currentOrdinal)
  return selectedField
}

export const changeSelectedFieldToPreviousField = (lastOrdinal, fieldList) =>
  _.find(fieldList, (field) => field.ordinal === lastOrdinal)

export const disableNextButton = (currentOrdinal, fieldList) => {
  if (fieldList.length <= 1) return true
  return currentOrdinal === fieldList[fieldList.length - 1].ordinal
}

export const disablePreviousButton = (currentOrdinal, fieldList) => {
  return currentOrdinal === fieldList[0].ordinal
}

export const checkIfTheFieldsShouldBeValidated = (fieldList, navigationOrdinals, errorFields) => {
  if (errorFields) {
    const errorFieldsKeys = _.keysIn(errorFields)
    const filteredErrorFields = fieldList.filter((field) => errorFieldsKeys.includes(field.id))
    const fieldsToBeValidated = filteredErrorFields.filter((field) =>
      navigationOrdinals.includes(field.ordinal)
    )
    return !(fieldsToBeValidated.length > 0)
  }
  return true
}

export const disableForm = (selectedSvf) => {
  if(_.isEmpty(selectedSvf)){
    return false
  }

  if (selectedSvf?.training) {
    return false
  }
  if (selectedSvf?.status === 'COMPLETED') {
    return true
  }
  if (!selectedSvf?.scheduleEndTime && selectedSvf?.allowSubmission) {
    return false
  }
  return showFormBasedOnDiaryCompletion(
    selectedSvf,
    selectedSvf?.allowDiaryCompletion,
  )
}

export const showFormBasedOnDiaryCompletion = (selectedSvf, allowDiaryDisplay) => {
  const currentDateTime = getCurrentDateTime()
 if(_.isEqual(selectedSvf.frequency, 4) && (selectedSvf.svfTimeslot.startTime != '00:00:00' || selectedSvf.svfTimeslot.endTime != '23:59:59')){
    const scheduleStartTime = getScheduleStartTime(selectedSvf, null)
    const scheduleEndTime = getScheduleEndTime(selectedSvf, allowDiaryDisplay, null)    
    return !(moment(currentDateTime).isBetween(moment(scheduleStartTime), moment(scheduleEndTime), 'minutes') && selectedSvf.allowSubmission)
  }
  const scheduleStartTime = getScheduleStartTime(selectedSvf, selectedSvf.scheduleStartTime)
  const scheduleEndTime = getScheduleEndTime(selectedSvf, allowDiaryDisplay, selectedSvf.scheduleEndTime)
  if (
    moment(currentDateTime).isBetween(moment(scheduleStartTime), moment(scheduleEndTime), 'minutes')&&
    selectedSvf.allowSubmission
  ) {
    return false
  } else {
    return true
  }
}

export const getCurrentDateTime = () => {
  return moment().format(DATE_TIME_FORMAT);
}
export const getScheduleStartTime = (selectedSvf, startTime) => {
  const baseTime = startTime ? moment(startTime) : moment()
  const scheduleStartTime = baseTime.set('hour', moment.utc(selectedSvf?.svfTimeslot?.startTime, 'HH:mm:ss').format('HH'))
  .set('minute', moment.utc(selectedSvf?.svfTimeslot?.startTime, 'HH:mm:ss').format('mm'))
  .format(DATE_TIME_FORMAT)
  return scheduleStartTime
}
export const getScheduleEndTime = (selectedSvf, allowDiaryDisplay, endTime) => {
  const eventTimeSlot = selectedSvf?.svfTimeslot
  const baseTime = endTime ? moment(endTime) : moment()
  const scheduleEndTime = baseTime.set('hour', moment.utc(eventTimeSlot?.endTime, 'HH:mm:ss').format('HH'))
  .set('minute', moment.utc(eventTimeSlot?.endTime, 'HH:mm:ss').format('mm'))
  .format(DATE_TIME_FORMAT)
const totalTimeForSubmission = allowDiaryDisplay
  ? moment(scheduleEndTime).add(selectedSvf.allowDiaryCompletion, 'minutes')
  : moment(scheduleEndTime).format(DATE_TIME_FORMAT)
  return totalTimeForSubmission
}
export const openForm = (selectedSvf, allowDiaryDisplay, subjectTimezone) => {
  const currentDateTime = getCurrentDateTime();
  const scheduleStartTime = getScheduleStartTime(selectedSvf, selectedSvf.scheduleStartTime)
  const scheduleEndTime = getScheduleEndTime(selectedSvf, allowDiaryDisplay, selectedSvf.scheduleEndTime)
  if (
    moment(currentDateTime).isSameOrAfter(moment(scheduleStartTime), 'day') &&
    moment(currentDateTime).isSameOrBefore(moment(scheduleEndTime), 'day')
  ) {
    return true
  } else if (moment(currentDateTime).isSameOrBefore(moment(scheduleStartTime), 'day')) {
    return true
  }
  else if (moment(currentDateTime).isSameOrAfter(moment(scheduleStartTime), 'day') && moment(currentDateTime).isSameOrAfter(moment(scheduleEndTime), 'day')) {
    return false
  }
}
