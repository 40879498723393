import React, { Component } from 'react'
import { Text, View, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {  getSubject } from '../selectors/commonSelector'
import { Feather } from '@expo/vector-icons'
import Logout from '../containers/siteStaff/logout'
import { getStudyData, getUserStudySite } from '../selectors/studyMetaData'
import PropTypes from 'prop-types'

export class SiteStaffHeaderRight extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidUpdate() {}

  drawerAction = () => {
    const { navigation } = this.props
    const parent = navigation.dangerouslyGetParent()
    const isDrawerOpen = parent && parent.state && parent.state.isDrawerOpen
    if (isDrawerOpen) {
      navigation.closeDrawer()
    } else {
      navigation.openDrawer()
    }
  }

  render() {
    const { study, subject, site, navigation, screenProps } = this.props
    return (
      <View style={{ flexDirection: 'row', flex: 2, alignItems: 'center' }}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 14, fontFamily: 'Inter', color: '#FFFFFF' }}>
            Site: {site.name}
          </Text>
          {study ? (
            <Text style={{ fontSize: 14, fontFamily: 'Inter', color: '#FFFFFF' }}>
              Study: {study.studyNumber}
            </Text>
          ) : (
            <Text></Text>
          )}
          {subject ? (
            <Text style={{ fontSize: 14, fontFamily: 'Inter', color: '#FFFFFF' }}>
              Subject: {subject.subjectNo}
            </Text>
          ) : (
            <Text></Text>
          )}
          <View>
            <Logout navigation={navigation} screenProps={screenProps} />
          </View>
        </View>
        <TouchableOpacity
          onPress={() => this.drawerAction()}
          style={{ flex: 0.2, marginLeft: 20, marginRight: 5 }}
        >
          <Feather name="menu" size={24} color="#ffffff" />
        </TouchableOpacity>
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  site: getUserStudySite(state),
  study: getStudyData(state),
  subject: getSubject(state),
})

SiteStaffHeaderRight.propTypes = {
  study: PropTypes.shape({
    studyNumber: PropTypes.string
  }),
  subject: PropTypes.shape({
    subjectNo: PropTypes.string
  }),
  site: PropTypes.shape({
    name: PropTypes.string
  }),
  navigation: PropTypes.shape({
    dangerouslyGetParent: PropTypes.func,
    closeDrawer: PropTypes.func,
    openDrawer: PropTypes.func
  }),
}
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SiteStaffHeaderRight)
// export default siteStaffHeaderRight
