import _ from 'lodash'
import React, { Component } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import CheckBox from './CheckBox'
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import ErrorMessage from '../form/ErrorMessage'
import PropTypes from 'prop-types'

// const CheckboxItem = Checkbox.CheckboxItem;

const horizontalLayout = {
  containerStyle: {
    flexDirection: 'row',
  },
  wrapStyle: {
    flex: 1,
    marginVertical: 5,
    flexDirection: 'column',
    // alignItems: 'center',
    // backgrounColor: 'yellow'
  },
  labelStyle: {
    textAlign: 'center',
    // alignSelf: "center",
  },
}
const verticalLayout = {
  containerStyle: {
    flexDirection: 'column',
  },
  wrapStyle: {
    flexDirection: 'column',
    paddingVertical: 15,
    alignItems: 'flex-start',
    borderBottomWidth: 0.5,
    borderColor: '#e0e0e0',
    backgrounColor: 'green',
  },
  labelStyle: {
    alignItems: 'center',
    textAlign: 'center',
    // alignSelf: "flex-start",
  },
  scrollViewWrapperStyle: {
    flexDirection: 'row',
    paddingVertical: 15,
    alignItems: 'flex-start',
  },
}

export class MultiSelect extends Component {
  state = {
    selectedOptions: [],
  }

  componentDidMount() {
    this.selectDefaultOptions()
  }

  selectDefaultOptions = () => {
    const {
      field: { crfData },
      fieldAnswer,
    } = this.props
    this.setState({
      selectedOptions: this.placeSelectedOptions(fieldAnswer),
    })
  }

  placeSelectedOptions = (crfData) => {
    if (crfData && crfData.optionOid) {
      return JSON.parse(crfData.optionOid)
    }
    return []
  }

  selectOption = (option) => {
    const { selectedOptions } = this.state
    let latestOptions = []
    // Unselect an option
    if (selectedOptions.indexOf(option) !== -1) {
      latestOptions = selectedOptions
      latestOptions.splice(selectedOptions.indexOf(option), 1),
        this.setState({
          selectedOptions: latestOptions,
        })
      this.changeOptionsInFieldDecorator(latestOptions)
      // Select an option
    } else {
      latestOptions = [...selectedOptions, option]
      this.setState({
        selectedOptions: latestOptions,
      })
      this.changeOptionsInFieldDecorator(latestOptions)
    }
  }

  changeOptionsInFieldDecorator = (selectedOptions) => {
    const {
      field: { id },
      form,
    } = this.props
    const { setFieldsValue, validateFields  } = form
    setFieldsValue({
      [id]: !_.isEmpty(selectedOptions) ? JSON.stringify(selectedOptions) : null,
    })
    validateFields([id], { force: true })
  }

  computeStyles = (layout, style, options, fdUISchema) => {
    const optionWidth = 100 / options.length
    if (layout === 'horizontal') {
      let styles = { ...horizontalLayout }
      // styles.wrapStyle.marginVertical = 0
      styles.wrapStyle = {
        ...styles.wrapStyle,
        marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 5),
        marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 5),
        flexDirection: 'column',
      }
      // styles.wrapStyle.width= `${optionWidth}%`;
      return styles
    } else {
      let styles = { ...verticalLayout }
      styles.wrapStyle = {
        ...styles.wrapStyle,
        marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 5),
        marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 5),
      }
      return styles
    }
  }

  renderScoreLabels = (score, styles, selfScored) => {
    if (selfScored) {
      return <Text style={styles.labelStyle}>{score}</Text>
    } else {
      return null
    }
  }
  render() {
    const { selectedOptions } = this.state
    const {
      field: {
        dictionary: { options },
        id,
        isRequired,
        fieldGroup,
        uiSchema: fieldUISchema,
      },
      form,
      t,
      style,
      layout = 'vertical',
      commonOptions,
      labelOrder,
      commonOptionsScore,
      disabled,
      fieldAnswer,
      isGridView = false,
    } = this.props
    const { selfScored, uiSchema } = fieldGroup
    const styles = this.computeStyles(layout, style, options, fieldUISchema)
    const crfData = { ...fieldAnswer }
    return form.getFieldDecorator(id, {
      initialValue: crfData && !_.isEmpty(crfData.optionOid) ? JSON.parse(crfData.optionOid) : null,
      rules: isRequired && !disabled
        ? [
          {
            required: isRequired && !disabled,
            message: t('ReqField'),
          },
        ]
        : [] ,
    })(
      <View style={styles.containerStyle} testID={`${id}-option-view`} accessible={true}>
        {options.map((option, index) => (
          <TouchableOpacity
            onPress={() => (!disabled ? this.selectOption(option.oid) : null)}
            style={!isGridView ? styles.scrollViewWrapperStyle : styles.wrapStyle}
            accessible={!disabled}
            testID={`${id}-option-${index}-btn`}
          >
            {layout !== 'horizontal' &&
            this.renderScoreLabels(option.score, styles, selfScored)}
            <CheckBox
              disabled={disabled}
              checked={selectedOptions && selectedOptions.indexOf(option.oid) !== -1}
              accessible={!disabled}
              // onPress={() => this.selectOption(option.oid)}
              style={{
                fillColor: '#ffffff',
                tickColor: '#9155FD',
                borderColor: '#9e9e9e',
              }}
              testID={`${id}-checkable-${index}`}
            />
            {(!isGridView || (!commonOptions && labelOrder[0] === 'optionLabel')) && (
              <Text
                style={styles.labelStyle}
                testID={`${id}-label-${index}`}
                accessible={!disabled}
              >
                {option.value}
              </Text>
            )}
            {layout === 'horizontal' &&
              (!commonOptionsScore && labelOrder[0] === 'optionLabel'
                ? this.renderScoreLabels(option.score, styles, selfScored)
                : null)}
            {layout === 'horizontal' &&
              (!commonOptionsScore && labelOrder[0] === 'optionScore'
                ? this.renderScoreLabels(option.score, styles, selfScored)
                : null)}
            {!commonOptions && labelOrder[0] === 'optionScore' && (
              <Text
                style={styles.labelStyle}
                accessible={!disabled}
                testID={`${id}-label-${index}`}
              >
                {option.value}
              </Text>
              
            )}
          </TouchableOpacity>
          

          // <Text>{option.value}</Text>
        ))}
        {!isGridView && <ErrorMessage id={id} isRequired={isRequired} disabled={disabled} form={form} />}
      </View>
    )
  }
}

MultiSelect.defaultProps = {
  t: () => {},
  style: {},
  layout: 'vertical',
  commonOptions: false,
  labelOrder: ['optionLabel'],
  commonOptionsScore: false,
  disabled: false,
  fieldAnswer: {},
  isGridView: false,
};

MultiSelect.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  t: PropTypes.func,
  style: PropTypes.object,
  layout: PropTypes.string,
  commonOptions: PropTypes.bool,
  labelOrder: PropTypes.array,
  commonOptionsScore: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldAnswer: PropTypes.object,
  isGridView: PropTypes.bool,
};