import React, { Component } from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { clearSelectedSubject, retrieveSubjects, updateSubject } from '../actions/subject'
import { updateSelectedFormType } from '../actions/session'
import { retrieveSubjectsOfCaregiver, retrieveSubjectById } from '../actions/caregiver'
import SubjectList from '../components/subject/list'
import constants from '../constants/constants'
import { NavigationEvents } from 'react-navigation'
import moment from 'moment-timezone'
import { getUser } from '../selectors/commonSelector'
import { getUserStudySite } from '../selectors/studyMetaData'
import Loader from '../components/common/Loader'
import _ from 'lodash'
class SubjectListPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      searchTerm: '',
    }
  }

  componentDidMount(){
      this.getSubjects()
  }
  
  getSubjects = async () => {
    const {
      retrieveSubjects: retrieveSubjectsOfSite,
      siteId,
      currentUser,
      retrieveSubjectsOfCaregiver,
      navigation
    } = this.props
    const autoSelection = navigation.getParam('autoSelection', true);
    try {
      let subjects = []
      if (currentUser && currentUser.role === 'CAREGIVER') {
        subjects = await retrieveSubjectsOfCaregiver(currentUser.id)
      } else {
        subjects = await retrieveSubjectsOfSite(siteId)
      }
      this.setState({list: subjects})
      if(subjects?.length === 1 && autoSelection){
        this.onSubjectSelect(subjects[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  clearSubject = async () => {
    const { clearSelectedSubject} = this.props
    this.setState({searchTerm: '', list: []})
    clearSelectedSubject()
  }

  onSubjectSelect = async (subject) => {
    const {
      updateSubject: updateSelectedSubjectInStore,
      navigation,
      retrieveSubjectById,
      currentUser,
    } = this.props
    const { formCategory } = constants
    if (currentUser && currentUser.role === 'CAREGIVER') {
      await retrieveSubjectById(subject.id)
      navigation.navigate('Visits')
    } else {
      updateSelectedSubjectInStore({ ...subject, lastUpdatedDateOn: null }, currentUser.id)
      updateSelectedFormType(formCategory.RATER_ASSESSMENT, currentUser.id)
      moment.tz.setDefault(currentUser?.userData?.data?.timezone)
      if(navigation?.navigate){
        navigation.navigate('ClinicianDiary', { screen: 'Visits' })
      }
    }
  }

  onSearchTerm = (searchTerm) => {
    this.setState({searchTerm})
  }

  getList = () => {
    const {searchTerm, list} = this.state
    return !_.isEmpty(searchTerm) ?  _.filter(list,(subject) => _.includes(_.lowerCase(subject?.subjectNo), _.lowerCase(searchTerm))) : list
  }

  render() {
    const {  appType, screenProps: {t}, loading } = this.props
    const {searchTerm} = this.state

    if(loading){
      return (<Loader loading={loading} text={t('Loading')}/>)
    }else{
      return (
        <View style={{ flex: 1 }}>
        <NavigationEvents
          onDidFocus={() => {
            appType === constants.AppType.SITESTAFF && this.clearSubject()
          }}
        />
          <SubjectList list={this.getList()} onSubjectSelect={this.onSubjectSelect} onSearchTerm={this.onSearchTerm} searchTerm={searchTerm} t={t}/>
        </View>
      )
    }

    
  }
}

SubjectListPage.defaultProps = {
  siteId: '',
  updateSubject: () => null,
  appType: constants.AppType.SUBJECT,
  

}

SubjectListPage.propTypes = {
  siteId: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  updateSubject: PropTypes.func,
  appType: PropTypes.string,
  clearSelectedSubject: PropTypes.func,
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    getParam: PropTypes.func,
  }),
  screenProps:PropTypes.shape({
      t: PropTypes.func,
  }), 
  loading: PropTypes.bool 
}
const mapStateToProps = (state) => {
  return {
    siteId: getUserStudySite(state)?.id,
    currentUser: getUser(state),
    appType: state.appStatus.appType,
    loading: state.appStatus.loading,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveSubjects,
      updateSubject,
      retrieveSubjectsOfCaregiver,
      retrieveSubjectById,
      updateSelectedFormType,
      clearSelectedSubject,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SubjectListPage)
