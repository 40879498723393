import React, { useState } from 'react'
import { View, Text, TouchableOpacity, Platform } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import TextAvatar from 'react-native-text-avatar'
import WebAlert from '../../../../utils/WebAlert'
import { backgroundColor } from '../../../../containers/NavigationScreens'
import StudySiteInfo from './studySiteInfo'
import PropTypes from 'prop-types'

const Profile = (props) => {
  const {
    name,
    logoutAction,
    screenProps,
    fullName,
    role,
    organizationName,
    title,
    study,
    subject,
    site,
    versionInfo,
    navigation
  } = props
  const [isModalVisible, setModalVisible] = useState(false)
  const [canOpenProfileDetails, setCanOpenProfileDetails] = useState(false)
  const buttons = [ 
    { 'title': 'NO', 'action': () => setModalVisible(false) }, 
    { 'title': 'YES', 'action': () => logoutAction() } 
]
  const openProfile = () => {
    setCanOpenProfileDetails(!canOpenProfileDetails)
  }

  return (
    <View style={{ display: 'flex' }}>
      <View style={{ marginTop: 5 }}>
        <TouchableOpacity
          onPress={() => openProfile()}
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <TextAvatar backgroundColor={'#3a35414d'} textColor={'#fff'} size={50} type={'circle'}>
            {name}
          </TextAvatar>
          <View
            style={{
              width: 12,
              height: 12,
              border: '2px solid #fff',
              backgroundColor: '#56ca00',
              borderRadius: 50,
              alignSelf: 'flex-end',
              marginTop: -15,
            }}
          ></View>
        </TouchableOpacity>
      </View>
      {canOpenProfileDetails && (
        <View
          style={{
            flexDirection: 'column',
            backgroundColor: backgroundColor,
            position: 'absolute',
            zIndex: 2,
            marginTop: 70,
            marginLeft: -210,
            width: 270,
            height: 160,
            borderRadius: 5,
            borderColor: '#e5eaef',
            borderWidth: 0.5,
          }}
        >
          <View style={{ flexDirection: 'row', height: '75%' }}>
            <View style={{ margin: 8, justifyContent: 'center' }}>
              <TextAvatar
                backgroundColor={'#3a35414d'}
                textColor={'#fff'}
                size={50}
                type={'circle'}
              >
                {name}
              </TextAvatar>
              <View
                style={{
                  width: 12,
                  height: 12,
                  border: '2px solid #fff',
                  backgroundColor: '#56ca00',
                  borderRadius: 50,
                  alignSelf: 'flex-end',
                  marginTop: -15,
                }}
              ></View>
            </View>
            <View style={{ flexDirection: 'column', marginLeft: 15, marginTop: 15 }}>
              <Text style={{ fontSize: 16, fontFamily: 'Inter', fontWeight: '500' }}>
                {fullName?.length > 25 ? fullName?.substring(0, 22) + '...' : fullName}
              </Text>
              <Text
                style={{
                  marginTop: 3,
                  color: 'rgba(58,53,65,.50)',
                  fontSize: 14,
                  fontWeight: '500',
                }}
              >
                {title?.length > 25 ? title?.substring(0, 22) + '...' : title}
              </Text>
              <Text
                style={{
                  marginTop: 5,
                  color: 'rgba(58,53,65,.40)',
                  fontSize: 14,
                  fontWeight: '500',
                }}
              >
                {role?.length > 25 ? role?.substring(0, 22) + '...' : role}
              </Text>
              <Text
                style={{
                  marginTop: 3,
                  color: 'rgba(58,53,65,.68)',
                  fontFamily: 'Inter',
                  fontSize: 16,
                  fontWeight: '500',
                }}
              >
                {organizationName?.length > 25
                  ? organizationName?.substring(0, 22) + '...'
                  : organizationName}
              </Text>
            </View>
          </View>
          {study && study.studyNumber && (
            <View style={{ flexDirection: 'column', backgroundColor: backgroundColor }}>
              <StudySiteInfo
                study={study}
                subject={subject}
                site={site}
                versionInfo={versionInfo}
                navigation={navigation}
              />
            </View>
          )}
          <View
            style={{
              height: 40,
              borderColor: '#dbd1d1',
              borderTopWidth: 0.5,
              borderBottomWidth: 0.5,
              paddingLeft: 40,
              paddingTop: 5,
              backgroundColor: backgroundColor,
            }}
          >
            <TouchableOpacity
              onPress={() => setModalVisible(true)}
              style={{ flexDirection: 'row' }}
            >
              <Ionicons name="log-out-outline" size={25} color="rgba(58,53,65,.87)" />
              <Text
                style={{
                  color: 'rgba(58,53,65,.87)',
                  fontSize: 16,
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  width: 70,
                  marginLeft: 15,
                  marginTop: 2,
                }}
              >
                Logout
              </Text>
            </TouchableOpacity>
            {isModalVisible && Platform.OS == 'web' && (
              <WebAlert
                headerText={''}
                hideModal={() => setModalVisible(false)}
                t={screenProps.t}
                action={() => logoutAction()}
                message={screenProps.t('LogoutMessage')}
                buttons ={buttons}
              />
            )}
          </View>
        </View>
      )}
    </View>
  )
}


Profile.propTypes = {
  name: PropTypes.string,
    logoutAction: PropTypes.func,
    fullName: PropTypes.string,
    role: PropTypes.string,
    organizationName: PropTypes.string,
    title: PropTypes.string,
    study: PropTypes.instanceOf(Object),
    subject: PropTypes.instanceOf(Object),
    site: PropTypes.instanceOf(Object),
    versionInfo: PropTypes.instanceOf(Object),
    screenProps: PropTypes.shape({
    t: PropTypes.func,
  }),
  navigation: PropTypes.instanceOf(Object),
}


export default Profile
