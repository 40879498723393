import React, { Component } from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NavigationEvents } from 'react-navigation'
import { clearSelectedSubject } from '../actions/subject'
import { retrieveStudySites, updateSelectedStudySite } from '../actions/studySite'
import StudySiteList from '../components/studySite/list'
import Loader from '../components/common/Loader'
import PropTypes from 'prop-types'
import _ from 'lodash'

class SiteListPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      searchTerm: '',
    }
  }

  componentDidMount(){
    this.getStudySites()
  }

  getStudySites = async () => {
    const { retrieveStudySites, navigation} = this.props
    const autoSelection = navigation.getParam('autoSelection', true);
    try {
      const sites = await retrieveStudySites()
      if(sites?.length === 1 && autoSelection){
        this.onSiteSelect(sites[0])
      }
      this.setState({list: sites})
      
    } catch (error) {
      console.log(error)
    }
  }

  clearStudySite = async () => {
    const { updateSelectedStudySite, clearSelectedSubject} = this.props
    this.setState({searchTerm: '', list: []})
    clearSelectedSubject(null)
    updateSelectedStudySite(null)
  }

  onSiteSelect = async (site) => {
    const { updateSelectedStudySite, navigation } = this.props
    const autoSelection = navigation.getParam('autoSelection', true);
    await updateSelectedStudySite(site)
    if(navigation?.navigate){
      navigation.navigate('SubjectListPage', {autoSelection})
    }
  }

  onSearchTerm = (searchTerm) => {
    this.setState({searchTerm: searchTerm})
  }

  getList = () => {
    const {searchTerm, list} = this.state
    return !_.isEmpty(searchTerm) ? _.filter(list, (site) => _.includes(_.lowerCase(site?.name), _.lowerCase(searchTerm))) : list
  }
  
  render() {
    const { list, searchTerm} = this.state
    const {screenProps: {t}, loading} = this.props

    if(loading){
      return ( <Loader loading={loading} text={t('Loading')}/>)
    }else{
      return (
        <View style={{ flex: 1 }}>
          <NavigationEvents
            onDidFocus={() => {
              this.clearStudySite()
            }}
          />
          <StudySiteList list={this.getList()} onSiteSelect={this.onSiteSelect} onSearchTerm={this.onSearchTerm} searchTerm={searchTerm} t={t} totalSitesCount={list?.length}/>
        </View>
      )
    }

   
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveStudySites,
      updateSelectedStudySite,
      clearSelectedSubject
    },
    dispatch
  )
  const mapStateToProps = (state) => {
    return {
      loading: state.appStatus.loading,
    }
  }

  
SiteListPage.propTypes = {
  retrieveStudySites: PropTypes.func,
  updateSelectedStudySite: PropTypes.func,
  clearSelectedSubject: PropTypes.func,
  navigation: PropTypes.shape({
      navigate: PropTypes.func,
      getParam: PropTypes.func,
  }),
   screenProps:PropTypes.shape({
      t: PropTypes.func,
    }),
    loading: PropTypes.bool
}
export default connect(mapStateToProps, mapDispatchToProps)(SiteListPage)

