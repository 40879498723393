import storage from '../utils/storage/storage'
import showToast from '../utils/toast'
import createActionType from '../utils/action'
import moment from 'moment'
import api from '../utils/api'
import jwt_decode from 'jwt-decode'
import { store } from '../store/configStore'
import _ from 'lodash'
import { Platform } from 'react-native'
import constants from '../constants/constants'
import { updateAppType, updateOnMobileStatus } from './storeAppStatus'
import Constants from 'expo-constants'
import RenderHTML from 'react-native-render-html'
import openapi from '../utils/openapi'
import NavigationService from '../containers/navigationService'
import { SESSION_TIMEOUT, updateCurrentUserId } from './session'
import { clearUserStorage } from './syncQueue'
import { getUserId } from '../selectors/user'
import { getUser } from '../selectors/commonSelector'
import enLocale from '../utils/localization/locale_en.json'

export const CLINRO_LOGIN_REQUEST = createActionType('CLINRO_LOGIN_REQUEST')
export const CLINRO_LOGIN_FAILURE = createActionType('CLINRO_LOGIN_FAILURE')
export const CLINRO_LOGOUT_REQUEST = createActionType('CLINRO_LOGOUT_REQUEST')
export const CLINRO_LOGOUT_SUCCESS = createActionType('CLINRO_LOGOUT_SUCCESS')
export const CLINRO_LOGOUT_FAILURE = createActionType('CLINRO_LOGOUT_FAILURE')
export const UPDATE_LOGGEDIN_USER = createActionType('UPDATE_LOGGEDIN_USER')
export const UPDATE_USERS = createActionType('UPDATE_USERS')
export const UPDATE_USER_PRIVILEGES = createActionType('UPDATE_USER_PRIVILEGES')
export const UPDATE_USER_DETAILS = createActionType('UPDATE_USER_DETAILS')
export const UPDATE_PRIVACY_POLICY = createActionType('UPDATE_PRIVACY_POLICY')
export const UPDATE_PASSWORD_EXPIRY = createActionType('UPDATE_PASSWORD_EXPIRY')
export const UPDATE_RESET_PASSWORD = createActionType('UPDATE_RESET_PASSWORD')
export const UPDATE_IS_SUBJECT_REGISTERED = createActionType('UPDATE_IS_SUBJECT_REGISTERED')

const loginRequest = () => ({
  type: CLINRO_LOGIN_REQUEST,
})

const loginFailure = () => ({
  type: CLINRO_LOGIN_FAILURE,
})

export const updateUsers = (users) => ({
  type: UPDATE_USERS,
  users,
})

export const resetCurrentUser = () => async (dispatch, getState) =>{
  dispatch(clearUserStorage())
}

export const sessionTimeout = (timeout, userId) => ({
  type: SESSION_TIMEOUT,
  timeout,
  userId
})

export const storePin = (pin) => async (dispatch, getState) => {
  let users = getState().users
  const user = getUser(getState())

  users[user.id] = {
    ...users[user.id],
    pin,
  }
  dispatch(updateUsers(users))
}

export const logout = () => async (dispatch, getState) => {
  dispatch({ type: CLINRO_LOGOUT_REQUEST })
  try {
    dispatch(clearUserStorage())
  } catch (error) {
    dispatch({ type: CLINRO_LOGOUT_FAILURE })
  }
}

const errorMessage = (t) => {
  Constants.expoConfig.extra.nativeApp
    ? showToast(t('InvalidUsernamePassword'), 'danger', 3000)
    : showToast(<RenderHTML source={{ html: t('InvalidUsernamePasswordWeb')}} />, 'danger', 3000)
}

export const login = (username, password, t, primaryOrganizationCode) => async (dispatch, getState) => {
  dispatch(loginRequest())
  try {
    let { users } = getState()
    const data = {
      username,
      password,
      grantType: 'password',
    }
    const tokenResponse = await openapi.post(`/realms/${primaryOrganizationCode}/users/login`, data)
    await dispatch(updateAppType(constants.AppType.SITESTAFF))
    const userData = await openapi.get(`/${primaryOrganizationCode}/users/${data.username}`, {
      headers: {
        Authorization: tokenResponse.data['token']
      },
    })
    const userDetails = {
      tokenDetails:{
        access_token: tokenResponse.data['token'],
        refresh_token: tokenResponse.data['refreshToken']
      },
      id: userData?.data?.id,
      userData: {
        data: userData?.data
      },
      role: constants.AppType.SITESTAFF
    }
    if (!_.isEmpty(users[userDetails.id])) {
      delete users[userDetails.id]
    }
    users = {
      ...users,
      [userDetails.id]: {
        ...userDetails,
      },
    }
    moment.tz.setDefault(userData?.data?.timezone)
    dispatch(updateCurrentUserId(userDetails?.id))
    dispatch(updateUsers(users))
    dispatch(loginFailure()) // to stop loading
  } catch (error) {
    console.log(error)
    showLoginErrorMessage(error, t)
    dispatch(loginFailure())
    throw error
  }
}

const showLoginErrorMessage = (error ,t) => {
  if (error.response) {
    switch (error.response.status) {
      case 404:
        showToast(t(Constants.expoConfig.extra.nativeApp ?  'InvalidUsernamePassword' : 'InvalidPasscode'), 'danger', 3000)
        break
      case 400:
        errorMessage(t)
        break
      case 401:
        errorMessage(t)
        break
      case 403:
        showToast(t(Constants.expoConfig.extra.nativeApp ?  'InvalidUsernamePassword' : 'NoClinroAccessPrivilege'), 'danger', 3000)
        break
      case 500:
        if (error.response.data && Number(error.response.data)) {
          error.response.message = getMessage(Number(error.response.data))
          break
        }
        showToast(t('SomethingWentWrong'), 'danger', 3000)
        break
      default:
        showToast(t('NetworkError'), 'danger', 3000)
    }
  } else {
    showToast(t('NetworkError'), 'danger', 3000)
  }
}

export const validateToken = async () => {
  try {
    const user = getUser(store.getState())
    if (_.isEmpty(user)) {
      return {}
    }
    let decodedAccessToken = jwt_decode(user.tokenDetails['access_token'])
    let decodedRefreshToken = jwt_decode(user.tokenDetails['refresh_token'])
    if (
      Date.now() >= decodedAccessToken.exp * 1000 &&
      Date.now() >= decodedRefreshToken.exp * 1000
    ) {
      store.dispatch(logout(true))
      navigateToSessionTimeout()
    } else if (Date.now() >= decodedAccessToken.exp * 1000) {
      await getUpdatedTokenDetails()
      return getAccessToken()
    }
    return getAccessToken()
  } catch (error) {
    console.log(error)
  }
}

const navigateToSessionTimeout = () => {
  const onMobile = store.getState()?.appStatus?.onMobile
  if (Platform.OS === 'web') {
    storage.clear()
  }
  store.dispatch(sessionTimeout(true, getUserId(store.getState())))
  store.dispatch(updateOnMobileStatus(onMobile))
  NavigationService.navigate('SessionTimeout')
}

const getUpdatedTokenDetails = async (t) => {
  try {
    const users = store.getState().users
    let user = getUser(store.getState())
    const primaryOrgCode = _.get(user?.userData?.data,'primaryOrganizationCode', "")
    const res = await api.post(`/${primaryOrgCode}/users/${user?.id}/refreshToken`)
    user.tokenDetails = {
      access_token: res.data?.['token'],
      refresh_token: res.data?.['refreshToken']
    }
    users[user.id] = user
    store.dispatch(updateUsers(users))
  } catch (error) {
    console.log(error)
    if (error.response) {
      if (error.response.status === 400) {
        showToast(enLocale.SessionTimeoutMessage, 'danger', 3000)
      }
    } else {
      showToast(enLocale.SomethingWentWrong, 'danger', 3000)
    }
  }
}

export const getAccessToken = () => {
  const currentUser = getUser(store.getState())
  if (!currentUser) {
    return {}
  }
  return {
    accessToken: currentUser.tokenDetails['access_token']
  }
}

export const updateSession = (sessionInfo) => (dispatch, getState) => {
  const {
    users: {
      currentUser: { id },
    },
  } = getState()
  let { users } = getState()
  users = {
    ...users,
    [id]: {
      ...users[id],
      sessionInfo,
    },
  }
  dispatch(updateUsers(users))
}

export const updatedLoggedinUser = (subjectData) => (dispatch) => {
  let user = {
    id: subjectData.id,
    firstName: subjectData.firstName,
    lastName: subjectData.lastName,
    age: subjectData.age,
    gender: subjectData.gender,
    dob: subjectData.dob,
    phoneNo: subjectData.phoneNo,
    email: subjectData.email,
    userId: subjectData.userId,
    role: subjectData.role,
    language: subjectData.subject?.language,
    resetPassword: subjectData.resetPassword,
    subjectId: subjectData.id,
    studySite: subjectData.studySite,
    primaryOrganizationCode: subjectData?.primaryOrganizationCode,
    studyId: subjectData?.studyId,
    siteId: subjectData?.siteId,
    privacyPolicy: { ...subjectData?.privacyPolicy },
    passwordExpired: subjectData?.passwordExpired,
    isSubjectRegistered: subjectData?.isSubjectRegistered,
  }
  dispatch({
    type: UPDATE_LOGGEDIN_USER,
    user,
  })
}

export const getDeviceStatus = () => {
  if (Platform.OS === 'web') {
    return navigator.onLine
  }
  return store.getState().appStatus.isDeviceOnline
}

export const handleLogout = (data) => async (dispatch) => {
  try {
    await api.post(
      `${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users/${data?.subject?.id}/logout`,
      data
    )
  } catch (error) {
    console.log(error)
  }
}

const getMessage = (messageKey) => {
  return _.filter(constants.errorMessageType.filter((ele) => ele.key == messageKey))[0].message
}

export const updateUserPrivileges = (privileges, userId) => (dispatch, getState) =>{
  dispatch({
    type: UPDATE_USER_PRIVILEGES,
    privileges,
    userId
  })
}

export const updateUserDetails = (subject, userId) => (dispatch, getState) =>{
  dispatch({
    type: UPDATE_USER_DETAILS,
    subject,
    userId
  })
}

export const updatePrivacyPolicy= (agreedToTerms) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_PRIVACY_POLICY,
    agreedToTerms,
    userId: getUserId(getState())
  })
}


export const updatePasswordExpiry= (passwordExpired) => async (dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD_EXPIRY,
    passwordExpired,
    userId: getUserId(getState())
  })
}

export const updateResetPassword= (resetPassword) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_RESET_PASSWORD,
    resetPassword,
    userId: getUserId(getState())
  })
}


export const updateIsSubjectRegistered= (isSubjectRegistered) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_IS_SUBJECT_REGISTERED,
    isSubjectRegistered,
    userId: getUserId(getState())
  })
}
