import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import Header from '../header'
import { backgroundColor } from '../../../containers/NavigationScreens'
import constants from '../../../constants/constants'
import Banner from '../../Banner'
import ProfileHeader from '../Profile/index'
import PropTypes from 'prop-types'

const SiteStaffProfileHeader = (props) => {
  const { title, navigation, canShowBackIcon, appType, screenProps, fromDiaryScreen, onBack } = props

  return (
    <View
      style={{
        backgroundColor: backgroundColor,
        height: 120,
      }}
    >
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: 5 }}>
        <Header navigation={navigation} title={title} canShowBackIcon={canShowBackIcon} onBack={onBack}></Header>
        <ProfileHeader navigation={navigation} screenProps={screenProps} />
      </View>
      {appType === constants.AppType.SITESTAFF && !fromDiaryScreen && (
        <View style={{ marginBottom: 10, marginTop: 10, zIndex: -1 }}>
          <Banner />
        </View>
      )}
    </View>
  )
}

const mapStateToProps = (state) => ({
  appType: state.appStatus.appType,
})

SiteStaffProfileHeader.propTypes = {
  title: PropTypes.string,
   navigation: PropTypes.shape({
      navigate: PropTypes.func,
    }), 
    canShowBackIcon: PropTypes.bool, 
    appType: PropTypes.string, 
    screenProps: PropTypes.shape({
      t: PropTypes.func,
    }), 
    fromDiaryScreen: PropTypes.bool, 
    onBack: PropTypes.func,
}
export default connect(mapStateToProps, null)(SiteStaffProfileHeader)
