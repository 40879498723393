import React, { useMemo } from 'react';
import { View, Platform } from 'react-native';
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType';
import { getTimezoneCode } from '../../utils/util';
import PropTypes from 'prop-types';
import constants from '../../constants/constants';
import Datepicker from '../webDatePicker/Datepicker';
import moment from 'moment';
import locale_codes from '../../utils/localization/localeConstants';
import ErrorMessage from '../form/ErrorMessage';


const { dateFormats, timeFormats, datepickerMode, platForm } = constants;

const DateTime = ({
  form,
  field: { id, isRequired, uiSchema: fdUISchema },
  t,
  subjectTimezone,
  disabled,
  fieldAnswer,
  locale,
  field
}) => {
  const { setFieldsValue, getFieldDecorator, validateFields  } = form

  const changeDate = (date) => {
    setFieldsValue({ [id]: date });
    validateFields([id], { force: true })
    return date;
  }

  const timeFormat = useMemo(() => {
    if(Platform.OS === platForm.web){
      const format = moment.localeData(locale).longDateFormat('LT');
      const is12HourFormat = format.includes('A') || format.includes('a');
      return is12HourFormat ? timeFormats.timeFormat12 : timeFormats.timeFormat24;
    } 
    if(Platform.OS === platForm.ios){
    return locale_codes[locale]?.time12 ? timeFormats.timeFormat12 : timeFormats.timeFormat24;

    }
    return timeFormats.timeFormat12
  }, [locale])

  

  const crfData = { ...fieldAnswer }

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
        marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
      }}
    >
      {getFieldDecorator(id, {
        initialValue: crfData?.fieldValue ? crfData.fieldValue : null,
        valuePropName: Platform.OS === 'web' ? 'date' : 'value',
        rules: isRequired && !disabled
        ? [
          {
            required: isRequired && !disabled,
            message: t('ReqField'),
            whitespace: true,
          },
        ]
        : [] ,
        getValueFromEvent: changeDate,
      })(
        <Datepicker
          showTimeInput={true}
          timeFormat={timeFormat}
          placeHolder={t('SelectDate')}
          valueFormat={`${dateFormats.DATE} ${timeFormat}`}
          shortTimezone={getTimezoneCode(subjectTimezone)}
          disabled={disabled}
          testID={`DateTime-Field-${id}-view`}
          locale={locale}
          t={t}
          mode={datepickerMode.datetime}
          date={crfData?.fieldValue}
          timezone={subjectTimezone}
        />
      )}
      <ErrorMessage id={id} isRequired={isRequired} disabled={disabled} form={form} />
    </View>
  )
}

DateTime.defaultProps = {
  t: () => {},
  disabled: false,
  fieldAnswer: {},
  subjectTimezone: null,
}

DateTime.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  t: PropTypes.func,
  disabled: PropTypes.bool,
  fieldAnswer: PropTypes.object,
  subjectTimezone: PropTypes.string,
  locale: PropTypes.string.isRequired
}

export default DateTime
